/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on September 13, 2024 */



@font-face {
    font-family: 'sofia_probold';
    src: url('sofia_pro_bold_az-webfont.woff2') format('woff2'),
         url('sofia_pro_bold_az-webfont.woff') format('woff'),
         url('sofia_pro_bold_az-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'sofia_prolight';
    src: url('sofia_pro_light_az-webfont.woff2') format('woff2'),
         url('sofia_pro_light_az-webfont.woff') format('woff'),
         url('sofia_pro_light_az-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'sofia_promedium';
    src: url('sofia_pro_medium_az-webfont.woff2') format('woff2'),
         url('sofia_pro_medium_az-webfont.woff') format('woff'),
         url('sofia_pro_medium_az-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'sofia_proregular';
    src: url('sofia_pro_regular_az-webfont.woff2') format('woff2'),
         url('sofia_pro_regular_az-webfont.woff') format('woff'),
         url('sofia_pro_regular_az-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}
