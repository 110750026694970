.page.home {
  padding: 30px 0 50px 0;
  background-image: url(../../img/bg-typo-white.svg);
  background-position: bottom right;
  background-size: 100% auto;
  background-repeat: no-repeat;
  .home-wrapper {
    max-width: 500px;
  }
  .bonjour-title {
    padding: 30px 0;
    h1 {
      margin: 0 0 20px;
    }
    h2 {
      margin: 0;
    }
  }
  .btn {
    margin-top: 40px;
  }

  @media (screen and min-width: 500px) {
    background-image: url(../../img/bg-typo-white-desktop.svg);
    background-position: bottom right;
    background-size: 100% auto;
    background-repeat: no-repeat;
  }

  @media (screen and min-width: 1000px) {
    background-image: url(../../img/bg-typo-white-desktop.svg);
    background-repeat: no-repeat;
    background-size: 75% auto;
    background-position: bottom right;
  }
}
