.page.lettres {
  .from-to, #lettre-info {
    .container {
      max-width: 750px;
    }
  }
  .lettre-contenu {
    max-width: 750px;
  }
  .from-to {
    padding: 20px 0;
    .from-to-wrapper {
      display: flex;
      gap: 15px;
      .from, .to {
        h5 { margin: 0; }
      }
      .transit {
        flex: 1;
        display: flex;
        align-items: center;
        .imgFrom, .imgTo {
          height: 10px;
          width: auto;
        }
        .imgFrom {
          transform: translateX(1px);
        }
        .transit {
          flex: 1;
          height: 3px;
        }
      }
    }
  }
  .lettre-info {
    display: flex;
    padding: 25px 0;
    gap: 20px;
    .lettre-info-title {
      h1 {
        margin: 0;
      }
      h4 {
        margin: 0;
      }
    }
  }
  .lettre-action  {
    display: flex;
    padding-bottom: 25px;
    gap: 20px;
    .lettre-action-player {
      flex: 1;
      background-color: #FFF;
      border-radius: 100px;
      padding: 20px 35px 20px 25px;
    }
    .lettre-action-viewer {
      display: flex;
      align-items: center;
      justify-content: center;
      .btn-link {
        img {
          width: 35px;
        }
      }
    }
  }
  .lettre-contenu {
    padding: 25px 0 0 0;
    p {
      margin-bottom: 15px;
    }
  }
  .lettre-suivante {
    margin-bottom: 50px;
    margin-top: 50px;
  }

  @media screen and (min-width: 700px) {
    #lettre-info .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .lettre-info {
        flex: 1;
      }
      .lettre-action {
        flex: 0 0 45%;
        padding: 0;
        height: 65px;
      }
    }
  }
}


.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 1000;
  .modal-content {
    border-radius: 0;
    position: relative;
    width: 100vw;
    height: calc(100vh - 100px);
    overflow: hidden;
    background-color: #fff;
    z-index: 1;
    .swiper {
      height: 100%;
      .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
      .swiper-slide img {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
      }
    }
  }
  .modal-actions {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100px;
    width: 100vw;
    background-color: #fff;
    z-index: 2;
    .modal-action {
      background: none;
      border: none;
      cursor: pointer;
      padding: 0;
      font-size: 3rem;
      color: #000;
      width: 25vw;
      svg {
        height: 25px;
      }
    }
  }

  .modal-body img {
    max-width: 100%;
    height: auto;
  }

}
