header.header {
  padding: 40px 0 15px 0;
  &.hide {
    display: none;
  }
  .header-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    .btn-link {
      h5 {
        margin: 0;
      }
      img {
        height: 20px;
        width: auto;
      }
    }
  }
}
