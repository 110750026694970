.page.playlist {
  .playlist-title {
    padding: 50px 0 40px 0;
    h1 {
      margin: 0 0 15px 0;
    }
    p {
      margin: 0;
    }
  }
  .playlist-player {
    z-index: 10;
    padding: 25px 0;
    .playlist-player-wrapper {
      background-color: #FFF;
      border-radius: 100px;
      padding: 25px 25px 25px 25px;
      display: flex;
      gap: 20px;
      position: relative;
      h5 {
        margin: 0;
        position: absolute;
        top: 10px;
        left: 65px;
        right: 75px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .rhap_container {
        flex: 1;
      }
      .action-next {
        img {
          height: 20px;
          width: auto;
        }
      }
    }
  }
  .playlist-tracks {
    border-top: 3px solid #000;
    .playlist-track {
      padding: 20px 0;
      border-top: 1px solid #000;
      cursor: pointer;
      &.active {
        background-color: #000000;
        color: #fff;
      }
      .playlist-track-wrapper {
        display: flex;
        align-items: center;
        .number {
          flex: 0 0 20px;
        }
        .from {
          flex: 1;
          display: flex;
          align-items: center;
          gap: 10px;
          .from-date {
            margin-bottom: 5px;
          }
        }
        .time {
          text-align: right;
        }
        h4, h5 {
          margin: 0;
        }
      }
    }
  }
}
