#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page {
  flex: 1;
}

.rhap_container {
  .rhap_main {
    position: relative;
    .rhap_progress-section {
      display: none;
    }
    .rhap_controls-section {
      order: 1;
      flex: 1;
      display: flex;
      gap: 20px;
      .rhap_main-controls {
        button {
          border: none;
          background-color: transparent;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;
          img {
            height: 20px;
            width: auto;
          }
        }
      }
      .rhap_progress-container {
        flex: 1;
        display: flex;
        align-items: center;
        .rhap_progress-bar {
          width: 100%;
          height: 3px;
          background-color: #000;
          .rhap_progress-filled {
            min-width: 3px;
            height: 10px;
            background-color: #000;
            transform: translateY(-3.5px);
            border-radius: 10px;
          }
        }
      }
      .rhap_current-time {
        position: absolute;
        left: 40px;
        top: calc(50% + 10px);
        font-size: 12px;
        line-height: 1;
      }
      .rhap_total-time {
        position: absolute;
        right: 0;
        top: calc(50% + 10px);
        font-size: 12px;
        line-height: 1;
      }
    }
  }
}
