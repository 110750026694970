.page.login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100svw;
  height: 100svh;
  background: url('../../img/bg-typo-green.svg');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: bottom center;
  .login-wrapper {
    max-width: 300px;
  }
  .error {
    padding: 25px 0;
  }
  h1 {
    line-height: 80px;
    margin: 0 0 50px 0;
  }
  .input-group {
    position: relative;
    margin-bottom: 50px;
    input {
      border: none;
      border-bottom: 2px solid #000000;
      width: 100%;
      height: 35px;
      z-index: 2;
      border-radius: 0;
      padding-left: 0;
      padding-right: 0;
      &::placeholder {
        color: #fff;
      }
    }
    label {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 14px;
      transition: all 250ms;
      z-index: 1;
    }
    input:focus + label, input:not(:placeholder-shown) + label {
      transform: translateY(-100%);
      top: 0;
    }
  }
  button {
    margin-top: 25px;
  }

  @media (screen and min-width: 500px) {
    background: url('../../img/bg-typo-green-desktop.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: bottom center;
  }

  @media (screen and min-width: 1000px) {
    background: url('../../img/bg-typo-green-desktop.svg');
    background-repeat: no-repeat;
    background-size: 75% auto;
    background-position: bottom right;
  }
}
