/**
  sofia_prolight
  sofia_promedium
  sofia_proregular
  sofia_probold
 */
@import "fonts/stylesheet.css";

$black: #000;
$gray-strong: #747373;
$gray-medium: #D2D2D2;
$gray-light: #F7F3F3;
$white: #FFFFFF;
$green: #BBE19D;
$yellow: #FFE792;
$blue: #B1ECF5;
$pink: #ECC7EF;

$colors: (
  "black": #000000,
  "gray-strong": #747373,
  "gray-medium": #D2D2D2,
  "gray-light": #F7F3F3,
  "white": #FFFFFF,
  "green": #BBE19D,
  "yellow": #FFE792,
  "blue": #B1ECF5,
  "pink": #ECC7EF
);

@each $name, $color in $colors {
  .bg-#{$name} { background-color: $color; }
  .bg-#{$name}-50 { background-color: mix($color, #FFFFFF, 50%); }
  .text-#{$name} { color: $color; }
  .fill-#{name} { fill: $color; }
  .stroke-#{name} { stroke: $color; }
}

.text-center {
  text-align: center;
}

* {
  outline: none;
}

body {
  margin: 0;
  font-family: Sofia, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
  font-size: 16px;
}

.container {
  width: calc(100vw - 60px);
  margin: 0 auto;
  gap: 20px
}

.ms-auto { margin-left: auto; }
.me-auto { margin-right: auto; }

h1, h2, h3, h4, h5 {
  font-family: "sofia_promedium", serif;
  font-weight: 500;
  line-height: 1;
}

h1 {
  font-size: 60px;
  span {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: 60px;
      bottom: -16px;
      width: 200px;
      height: 40px;
      border-radius: 10px;
      background-color: $green;
      z-index: -1;
    }
  }
}

h2 {
  font-size: 26px;
}

h3 {
  font-size: 16px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 11px;
  text-transform: uppercase;
}

p {
  font-family: "sofia_proregular", serif;
  font-size: 16px;
  line-height: 20px;
  color: $black;
  small {
    font-family: "sofia_prolight", serif;
    font-size: 12px;
    line-height: 20px;
    color: $black;
  }
}

.btn {
  display: inline-block;
  background-color: $black;
  padding: 10px 45px;
  color: $white;
  text-decoration: none;
  font-size: 18px;
  border-radius: 50px;
  font-family: "sofia_proregular", serif;
  font-weight: 500;
  border-width: 3px;
  border-style: solid;
  border-color: $black;
  transition: all 250ms;
  cursor: pointer;


  &:hover {
    background-color: $white;
    color: $black;
  }
  &:disabled {
    background-color: $gray-medium;
    border-color: $gray-medium;
    color: $white;
  }
}

.btn-link {
  display: flex;
  background-color: transparent!important;
  text-transform: uppercase;
  border: none;
  box-shadow: none;
  color: $black;
  padding-left: 0;
  padding-right: 0;
  justify-content: center;
  align-items: center;
}

.squares {
  margin-top: 25px;
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
  .square {
    display: inline-block;
    width: 100px;
    aspect-ratio: 1;
    border-radius: 5px;
  }
}

.timeline {
  width: 100vw;
  position: relative;
  .timeline-line {
    position: absolute;
    height: 2px;
    left: 0;
    right: 0;
    top: 30px;
    background-color: $gray-medium;
  }
  .timeline-wrapper {
    display: flex;
    flex-wrap: nowrap;
    overflow: scroll;
    gap: 20px;
    padding: 0 30px 10px 30px;
  }
}

.timeline-item {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  .timeline-item-img {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 4px solid;
    width: 53px;
    height: 53px;
    border-radius: 55px;
    position: relative;
    img {
      width: 48px;
      height: auto;
    }
    &:after {
      content: '';
      width: 20px;
      height: 20px;
      border: 4px solid $white;
      border-radius: 25px;
      background: $green;
      position: absolute;
      bottom: -3px;
      right: -3px;
      opacity: 0;
      transition: all 100ms;
    }
  }
  &.timeline-item-selected {
    .timeline-item-img {
      &:after {
        opacity: 1;
      }
    }
  }
  &.man {
    .timeline-item-img {
      border-color: $blue;
    }
  }
  &.woman {
    .timeline-item-img {
      border-color: $pink;
    }
  }
  h5 {
    margin: 3px 0 0 0;
    text-align: center;
  }
  &.timeline-item-big {
    .timeline-item-img {
      border: none;
      width: 70px;
      height: 70px;
      border-radius: 70px;
      img {
        width: 70px;
      }
      &:after {
        width: 23px;
        height: 23px;
        border: 3px solid $white;
      }
    }
  }
  &.timeline-item-small {
    .timeline-item-img {
      border: none;
      width: 40px;
      height: 40px;
      border-radius: 40px;
      img {
        width: 35px;
      }
      &:after {
        width: 10px;
        height: 10px;
        border: 3px solid $white;
      }
    }
  }
}
